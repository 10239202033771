

.descargaDiplomas {

  .AnimacionLoading {
    position:absolute;
    width:100%;
    height:100%;
    display: -webkit-flex;
    display:  flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    svg {
      width: 100% !important;
      height: 500px !important;
      //width: 70% !important;
      //margin-top: -80px !important;
      //margin-left: 50px !important;
    }
  }


  .membrete {
    float: left !important;
    width: 20% !important;
  }

  .btnCerrar {
    float: right !important;
    width: 5% !important;
    cursor: pointer !important;
  }

  .diplomas {
    position: relative;
    display: inline-block;
    text-align: center;

    .diplomaEtapa1y2 {
      //margin-top: 10px !important;
      width: 100% !important;
    }

    .nombre {
      //display: block !important;
      //z-index: 2;
      //margin-top: -28% !important;
      //margin-left: 32% !important;

      position: absolute;
      top: 57%;
      left: 52%;
      transform: translate(-52%, -57%);

      @media only screen and (max-width: 375px) and (max-width: 812px) {
        font-size: 9px !important;
      }

      @media only screen and (max-width: 414px) and (max-width: 736px) {
        font-size: 9px !important;
      }
    }
  }

  .botonDiploma {
    margin-top: 10px !important;
    position:absolute;
    width:100%;
    display: -webkit-flex;
    display:  flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;

    .botonDescarga {
      background-color: #6edbed !important;
      border-color: white !important;
      border-radius: 50px !important;
      color: #1b1464 !important;
      height: 60px !important;


      border-top-width: 3px;
      border-right-width: 3px;
      border-bottom-width: 3px;
      border-left-width: 3px;

      cursor: pointer !important;

      &:hover {
        background-color: #fcee21 !important;
      }
    }

  }

}
